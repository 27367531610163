import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const MusicPage = () => {
  return (
    <Layout>
      <h1 className="mobile-header lg:desktop-header">Music</h1>
      <div className="flex flex-col lg:flex-row justify-evenly ">
        <p className="mobile-body lg:dektop-body lg:max-w-md pt-4 lg:pt-0">
          <strong>
            “Good Music is good, no matter what kind of music it is”
          </strong>{" "}
          Miles Davis.
          <br />
          Twenty years ago, a pig farmer from Illnois, called Puerco, organized
          a music festival “FOR Musicians BY Musicians”. He called it the San
          Pancho Music Festival, hosting it in his backyard.
          <br />
          We got involved in year two, after meeting a cat called Geno who
          played music around town. I was in bed,one night reading, when I heard
          my husband’s voice coming thru a microphone next door. I thought he
          had popped out on the patio for a smoke.
          <br />
          I threw jeans over my jammie-bottoms, walked next door to find my guy
          and Geno singing a duet in front of a crowd. What in the Hell? I
          plopped down, got a limonada and listened to the boys playing some fun
          tunes.
          <br />
          Geno asked me to step up to sing. My throat sunk to my stomach. I
          hadn’t sung out loud in a long time. Flipping thru Geno’s songbook, I
          picked “Desperado” had him count me in, let ‘er rip, in a shy twirl my
          curl kind of way, hands in pockets, eyes on the front of my nose,
          instead of caressing the crowd. I finished to a lovely round of
          applause. It felt good. Really good. I had been with Bob for two years
          and he hadn’t a clue I could sing.
          <br />
          Geno mentioned the festival to us. The following week we strolled over
          to Puerco’s back yard to check out the scene, in that slinky
          nonchalant way musicians do. (I copied Bob!!!)
          <br />
          There were peeps setting up all over the place. Puerco’s wife
          Patricia, who I knew, was onstage playing her keyboards. I jumped up
          to say HI.
          <br />
          She asked if I sang. I Boldly said: YES!
          <br />
          She asked what I wanted to sing? I shrugged. She pulled out Whitney
          Houston’s “I Will Always Love You” and said: let’s play this. (What
          was I thinking?????)
          <br />
          We did. I sang it with heart, beautifully, strong, in tune, in tempo.
          It caught everyone’s attention.
          <br />
          Puerco looked over at Bob exclaiming: I didn’t know your gal could
          sing. Bob said: Neither Did I.
          <br />
          That very afternoon a guitar player from the Bayou,” Ronnie Ray from
          the other LA”, and a mandolin player named Blaine, BBQ Bob and I,
          stepped on stage to form the first version of Frida’s Eyebrow. We had
          so much goddamned fun playing together, that it was contagious.
          <br />
          That festival was a new beginning for Bob’s and my friendship. Not
          only did we have art, but now we had music. BBQ has shaped me into a
          musician, and given me a gift that I could not have found anywhere
          else.
          <br />
          Over the years, Frida’s Eyebrow has included: Peter Razza, Brent
          Barsotti, Chaz Eller, Julie and Will Caldwell, The Musician formerly
          known as Dave, Rick Kahn, Hank Fieger, Ronnie Ray, Blaine and various
          cameos. The music comes out of whoever gather’s together in our living
          room to make a Joyful Noise.
          <br />
          The San Pancho Festival has grown and morphed into a world class
          event, from the days in Puerco’s back yard. Frida’s eyebrow has played
          throughout. (That is until Covid Put a cork in it for a bit. <br />
          We have had some glorious moments performing, where the band and the
          audience were so in sync, it felt like a Love Fest, and for that we
          will keep practicing scales, learning new tunes that are relatable and
          funny and poetic to our goofy sense of reality.
        </p>
        <div className="flex flex-col gap-4 lg:pl-4">
          <StaticImage
            width={788}
            src="../images/music/frida-and-music.png"
            alt="music"
            placeholder="tracedSVG"
            className=""
          />

          <div className="flex gap-4 overflow-x-scroll overscroll-x-contain">
            <StaticImage
              width={648}
              src="../images/music/frida-and-music1.png"
              alt="music"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              width={648}
              src="../images/music/frida-sp.png"
              alt="music"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              width={648}
              src="../images/music/frida-and-music2.png"
              alt="music"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              width={648}
              src="../images/music/dana1.png"
              alt="music"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
          <div className="flex flex-row flex-wrap justify-evenly gap-4">
            <StaticImage
              width={312}
              height={416}
              src="../images/music/frida-eye.png"
              alt="music"
              placeholder="tracedSVG"
              layout="fixed"
            />
            <StaticImage
              width={500}
              height={357}
              src="../images/music/mackay.png"
              alt="music"
              placeholder="tracedSVG"
            />
            <StaticImage
              width={312}
              src="../images/music/bob-frida.png"
              alt="music"
              placeholder="tracedSVG"
            />
            <StaticImage
              width={500}
              src="../images/music/frida-s-2018.png"
              alt="music"
              placeholder="tracedSVG"
            />
            <StaticImage
              width={500}
              src="../images/music/dana-colectivo.png"
              alt="music"
              placeholder="tracedSVG"
            />
            <StaticImage
              width={312}
              src="../images/music/dana-mexico.png"
              alt="music"
              placeholder="tracedSVG"
            />

            <StaticImage
              width={312}
              src="../images/music/dana-2018-frida.png"
              alt="music"
              placeholder="tracedSVG"
            />
            <StaticImage
              width={500}
              src="../images/music/house.png"
              alt="music"
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MusicPage;
